import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Home";
import Mesa from "./Mesa";
import Matrix from "./Matrix";
import Cs50 from "./Cs50";
import Violin from "./Violin";
import Cac from "./Cac";
import Eng from "./Eng";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  { path: "*", Component: Home },
  { path: "/app", Component: App },
  { path: "/mesa", Component: Mesa },
  { path: "/cs50", Component: Cs50 },
  { path: "/violin", Component: Violin },
  { path: "/cac", Component: Cac },
  { path: "/eng", Component: Eng },
]);

root.render(
  <React.StrictMode>
    <Matrix />
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
