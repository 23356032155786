import { Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

function NUHeader({ page }) {
  return (
    <header className="App-Name">
      <Navbar
        class="text-white"
        variant="dark"
        fixed="top"
        bg="dark"
        color="light"
      >
        <Container>
          <Navbar.Brand as={Link} to="/" className="me-2">
            <img
              alt="e"
              src="/e.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
            <span className="ms-3">Enda Hughes</span>
          </Navbar.Brand>
          {page && (
            <Navbar.Brand className="me-auto">
              <span className="me-2 slash">/</span>
              <span>{page}</span>
            </Navbar.Brand>
          )}
          <a
            href="https://github.com/Enda111"
            target="_blank"
            rel="noreferrer nofollow"
            title="My GitHub Profile"
          >
            <img
              alt="Github"
              src="/pictures/ghLogo.png"
              width="30"
              height="30"
              className="d-inline-block align-right"
            />
          </a>
        </Container>
      </Navbar>
    </header>
  );
}

export default NUHeader;
