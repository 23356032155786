function MyVideo({ video }) {
  // Convert YouTube URL to embed URL if necessary
  const embedUrl = video.includes("youtube.com/shorts")
    ? video.replace("youtube.com/shorts", "youtube.com/embed")
    : video;


  return (
    <iframe
      style={{ aspectRatio: "560  / 315" }}
      src={embedUrl}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  );
}

export default MyVideo;
