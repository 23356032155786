import React from "react";
import "./App.css";
import { Container, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import NUHeader from "./NUHeader";
import MyVideo from "./MyVideo";

function Eng() {
  return (
    <div className="App">
      {/* Header Component */}
      <NUHeader page="Engineering Portfolio" />

      <Container className="p-3 mt-5">
        {/* Autonomous Robot Section */}
        <Card className="mb-5 Card-color Card-text text-start">
          <Card.Body>
            <h1 className="text-center" >Autonomous Robot Design</h1>
                {/* SPACE: Add spacing here */}
                <div className="spacing"></div>
            <Card.Text>
              The <strong>Autonomous Robot Design</strong> project demonstrates the synthesis of engineering principles and teamwork to create a robot capable of navigating a predefined maze autonomously. The project required overcoming technical, design, and coding challenges while adhering to constraints such as spatial boundaries, pre-programmed movement, and resource availability. The purpose was to design a functional robot that successfully met project specifications, utilizing an iterative design and testing approach.
            </Card.Text>

            <h2 className="section-header">Defining the Problem</h2>
            <Card.Text>
              <strong>Objective:</strong> The project encompassed three progressive challenges, each designed to enhance engineering, programming, and problem-solving skills. The specific objectives for each challenge were as follows:
              <br />
              <br />
              <strong>Challenge 1:</strong> The goal was to design, construct, and program a robot capable of traversing a 4-foot-long, 10-inch-wide path and halting at its end. This introductory task emphasized basic movements—traveling straight, turning, and stopping—executed through pre-programmed instructions, without reliance on sensors. This phase focused on structural integrity, motor alignment, and consistent power delivery, establishing a foundational understanding for subsequent challenges.
              <br />
              <br />
              <strong>Challenge 2:</strong> Building on the first task, this challenge required programming the robot to navigate a predefined maze with two turns, bordered by 6-inch walls, and stop at the endpoint. The robot's navigation relied on an ultrasonic sensor for detecting walls, with early iterations exploring dynamic responses to sensor feedback. Due to inconsistencies in sensor readings, the maze path was eventually hardcoded to ensure reliability.
              <br />
              <br />
              <strong>Challenge 3:</strong> The final challenge introduced an unknown maze on demonstration day. The robot had to dynamically detect walls and adjust its movements accordingly, utilizing the ultrasonic sensor for autonomous navigation. Unlike earlier tasks, pre-programmed paths were not feasible, necessitating a flexible, sensor-driven approach. This stage tested the integration of all prior developments and underscored adaptability in design and programming.
              <br />
              <br />
              The project demonstrated an iterative approach to design and programming, with each phase building on lessons learned, ultimately culminating in a responsive and adaptable robotic system.
            </Card.Text>

            <h2 className="section-header">Documenting the Process</h2>
            <Card.Text>
              <strong>Key Milestones:</strong>
              <ul className="adjusted-list">
                <li>
                  <strong>November 1st:</strong> Constructed the framework and installed motors and wheels. Resolved motor alignment issues through iterative adjustments.
                </li>
                <li>
                  <strong>November 3rd:</strong> Completed assembly and initial coding for straight movement using Arduino guides.
                </li>
                <li>
                  <strong>November 15th:</strong> Integrated a distance sensor for autonomous navigation and tested threshold calibration.
                </li>
              </ul>
            </Card.Text>
            <div className="media-container">
              <Card.Img src="/pictures/engineering/chassis.jpg" />
              <Card.Img src="/pictures/engineering/chassis+wheels.jpg" />
              <Card.Img src="/pictures/engineering/wiring.jpg" />
            </div>
            <Card.Text>Photos of the assembly process and wiring setup.</Card.Text>

            <h2 className="section-header">Videos</h2>
            <MyVideo video="https://youtube.com/shorts/NZdT0V0QQt0" />

            <div className="media-container">
              <Card.Img src="/pictures/engineering/maze.jpg" />
            </div>
            <Card.Text>AutoCAD drawing of the modular maze.</Card.Text>

            <h2 className="section-header">Exploring the Iterations</h2>
            <Card.Text>
              The design evolved significantly:
              <ul className="adjusted-list">
                <li>Resolved deviations in straight movement through precise PWM calibration.</li>
                <li>Improved sensor performance by adjusting thresholds and filters.</li>
                <li>Enhanced traction with modular weight adjustments.</li>
              </ul>
            </Card.Text>
            <div className="media-container">
              <Card.Img src="/pictures/engineering/weights.jpg" />
            </div>
            <Card.Text>After assembly adjustments.</Card.Text>

            <h2 className="section-header">Share Challenges and Solutions</h2>
            <Card.Text>
              <strong>Challenges:</strong> Addressed motor variability and environmental dust affecting traction. Collaborative problem-solving played a pivotal role in overcoming these issues.
            </Card.Text>
            <div className="media-container">
              <Card.Img src="/pictures/engineering/modified_front.jpg" />
              <Card.Img src="/pictures/engineering/modified_back.jpg" />
            </div>
            <Card.Text>Modified components.</Card.Text>

            <h2 className="section-header">Showcasing the Final Design</h2>
            <Card.Text>
              The final robot is designed for effective autonomous navigation, featuring a compact and sturdy chassis optimized for stability and performance. Integrated Arduino systems coordinate motor functions and sensor inputs, ensuring smooth operation. 

              A front-mounted ultrasonic distance sensor enables real-time obstacle detection and path adjustments, allowing the robot to navigate accurately and consistently. The streamlined design also facilitates easy maintenance and modular upgrades. This final version reflects the results of iterative improvements and a focus on functional, practical design.
            </Card.Text>
            <div className="media-container">
              <Card.Img src="/pictures/engineering/final_robot.jpg" />
              <Card.Img src="/pictures/engineering/side_profile.jpg" />
            </div>
            <Card.Text>High-quality photos of the final robot design.</Card.Text>

            <h2 className="section-header">Personal Reflections</h2>
            <Card.Text>
              This project significantly contributed to the development of essential engineering and teamwork skills. In particular, it deepened proficiency in Arduino programming, including the implementation of dynamic sensor-based navigation and fine-tuning motor controls for optimal performance. The iterative problem-solving process fostered critical thinking and adaptability, as challenges such as motor calibration, sensor inconsistencies, and environmental variables required creative and systematic approaches to resolve. 

              Collaborative teamwork played a pivotal role in the project's success, enabling the group to pool diverse expertise and perspectives. Key contributions included the seamless integration of ultrasonic sensors for autonomous navigation, the creation of detailed and organized documentation for both design and programming phases, and structural refinements to enhance the robot’s stability and functionality. 

              Beyond technical skills, this experience underscored the importance of clear communication and shared responsibility within a team setting, laying the foundation for future engineering endeavors.
            </Card.Text>

            <Card.Text>
              <strong>Sources and Tools:</strong> SparkFun Inventor’s Kit booklet, class materials, and peer feedback.
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default Eng;
