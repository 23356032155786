import React from "react";
// import logo from './logo.svg';
import "./App.css";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MyHeader from "./MyHeader";
import MyLink from "./MyLink";

function App() {
  return (
    <div className="App" style={{ overflow: "hidden" }}>
      <MyHeader />

      <Container className="p-3 mt-5 d-flex flex-column align-items-center">
        <MyLink link="/mesa" image="/pictures/mesaLogo.png" alt="MESA" />

        <MyLink
          link="/violin"
          image="/pictures/EndaHughesViolinLogo.png"
          alt="Violin"
        />

        <MyLink
          link="/cs50"
          image="/pictures/edxHarvardLogo.png"
          alt="CS50"
        />

        <MyLink
          link="/cac"
          image="/pictures/cac2.png"
          alt="CongressionalAppChallenge"
        />

        {/*
        <MyLink link="/app" image="/pictures/cac2.png" alt="Robotics" />

        <MyLink link="/app" image="/pictures/cac2.png" alt="Coding projects" />
        */}

        <div
          className="mini-name"
          style={{
            pointerEvents: "none",
            position: "relative",
            width: "680px",
            height: "700px",
            transform: "scale(.3)",
            marginTop: "-200px",
            marginBottom: "-200px",
          }}
        >
          <span className="e">e</span>
          <span className="n">n</span>
          <span className="d">d</span>
          <span className="a">a</span>
        </div>
      </Container>
    </div>
  );
}

export default App;
