import React from "react";
// import logo from './logo.svg';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// https://dev.to/javascriptacademy/matrix-raining-code-effect-using-javascript-4hep
// Adam Nagy
function Matrix() {
  // const [refresh, setRefresh] = useState(0);
  // useEffect(() => {
  //   const canvas = document.getElementById("Matrix");
  //   const context = canvas.getContext("2d");

  //   canvas.width = window.innerWidth;
  //   canvas.height = window.innerHeight;

  //   const katakana =
  //     "アァカサタナハマヤャラワガザダバパイィキシチニヒミリヰギジヂビピウゥクスツヌフムユュルグズブヅプエェケセテネヘメレヱゲゼデベペオォコソトノホモヨョロヲゴゾドボポヴッン";
  //   const latin = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  //   const nums = "0123456789";

  //   const alphabet = katakana + latin + nums;

  //   const fontSize = 16;
  //   const columns = canvas.width / fontSize;

  //   const rainDrops = Array.from({ length: columns }).fill(canvas.height);

  //   const draw = () => {
  //     context.fillStyle = "rgba(0, 0, 0, 0.05)";
  //     context.fillRect(0, 0, canvas.width, canvas.height);

  //     context.fillStyle = "#0F0";
  //     context.font = fontSize + "px monospace";

  //     for (let i = 0; i < rainDrops.length; i++) {
  //       const text = alphabet.charAt(
  //         Math.floor(Math.random() * alphabet.length)
  //       );
  //       context.fillText(text, i * fontSize, rainDrops[i] * fontSize);

  //       if (rainDrops[i] * fontSize > canvas.height && Math.random() > 0.975) {
  //         rainDrops[i] = 0;
  //       }
  //       rainDrops[i]++;
  //     }
  //   };

  //   const resizeListener = () => {
  //     setRefresh((r) => 1 + r);
  //   };
  //   window.addEventListener("resize", resizeListener);

  //   const interval = setInterval(draw, 50);
  //   return () => {
  //     clearInterval(interval);
  //     window.removeEventListener("resize", resizeListener);
  //   };
  // }, [refresh]);

  return <div></div>
  //return <canvas id="Matrix"></canvas>;
}

export default Matrix;
